.nav-list-item:hover .subnav-list {
    position: absolute;
    opacity: 1;
    display: block;
}

.bread-holder-loop {
    margin-left: 10px
}

.bread-home {
    margin-left: 8px;
}