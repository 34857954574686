  .error-message {   
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    border-bottom: 1px solid transparent;
    color: red;
    line-height: 14px;
    text-decoration: none;
  }

  p.reset-pass {   
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    border-bottom: 1px solid transparent;
    color: white;
    line-height: 14px;
    text-decoration: none;
    margin-bottom: 20px;
  }

  .btn.btn-login.btn-loading {
    background-position: 50% 50% !important;
    background-size: auto;
    background-repeat: no-repeat;
  }