/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.5); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.centralize {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.btn.btn-loading {
  background-position: 50% 50% !important;
  background-size: auto !important;
  background-repeat: no-repeat !important;
}

.signup-middle-right {
  margin-left: 0;
}

.signup-form {
  margin-top: 20px;
}

.delete-post {
  color: #F16177;
  text-decoration: underline;
  cursor: pointer
}