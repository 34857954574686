.w-embed-youtubevideo {
  width: 100%;
  position: relative;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-size: cover;
  background-position: 50% 50%;
}

.w-embed-youtubevideo:empty {
  min-height: 75px;
  padding-bottom: 56.25%;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.contentview-body h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 38px;
  line-height: 44px;
  font-weight: bold;
}

.contentview-body h2 {
  margin-top: 40px;
  margin-bottom: 10px;
  font-family: 'Product Sans Bold', sans-serif;
  color: #5d05d4;
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
}

.contentview-body p {
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 26px;
}

.contentview-body ul {
  margin-top: 0px;
  margin-bottom: 40px;
  padding-left: 40px;
}

.contentview-body li {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 26px;
}

.contentview-body strong {
  font-family: 'Product Sans Bold', sans-serif;
  color: #5d05d4;
  font-weight: 700;
}

.contentview-container {
  width: 100%;
  height: 100%;
  max-width: 940px;
}

.contentview-container.container-fp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.contentview-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Product Sans Regular', sans-serif;
  color: #7e7e7e;
}

.contentview-filhos-fp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 300px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #73edd8;
}

.contentview-text {
  padding: 40px 20px;
}

.contentview-fp-image {
  width: 40%;
  height: 100%;
  background-image: url('../images/fernando-removebg-preview2.png');
  background-position: 50% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.contentview-fp-phrase {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-family: 'Product Sans Medium', sans-serif;
}

.contentview-fp-ttitle {
  color: #5d05d4;
  font-size: 28px;
  line-height: 32px;
}

.contentview-fp-name {
  margin-top: 20px;
  font-family: 'Product Sans Regular', sans-serif;
  color: #fff;
  font-size: 20px;
}

.contentview-youtube-tip-image {
  margin-left: 30px;
  float: right;
}

.contentview-youtubevideo {
  margin-bottom: 40px;
}

.contentview-slide {
  height: auto;
  background-color: #fff;
}

.contentview-slide-img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 390px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #5d05d4;
  background-image: url('../images/italia.jpg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.contentview-slide-img.slide0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/slide0.jpeg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.contentview-slide-img.slide3 {
  background-image: url('../images/slide2.jpg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.contentview-slide-img.slide4 {
  background-image: url('../images/slide4.jpg');
  background-position: 50% 50%;
  background-size: contain;
}

.contentview-slide-img.slide5 {
  background-image: url('../images/slide5.jpg');
  background-position: 50% 50%;
  background-size: contain;
}

.contentview-slide-img.slide6 {
  background-image: url('../images/slide6.jpg');
  background-position: 50% 50%;
  background-size: contain;
}

.contentview-slider {
  height: 100%;
}

.mask {
  height: auto;
  background-color: #fff;
}

.contentview-slide-arrow {
  position: absolute;
  width: 60px;
  height: 60px;
  margin: 168px -20px auto;
  border-radius: 50%;
  background-color: #5d05d4;
  color: #fff;
  font-size: 25px;
}

.body {
  border-radius: 50%;
}

.slide-nav {
  display: none;
  background-color: #5d05d4;
}

.contentview-slide-text {
  overflow: scroll;
  margin-top: 30px;
  font-size: 16px;
  line-height: 25px;
}

.contentview-link-item {
  width: 100%;
  height: 100%;
  padding: 20px 20px 24px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(93, 5, 212, 0.1);
  border-radius: 4px;
  background-color: rgba(93, 5, 212, 0.1);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #5d05d4;
  text-decoration: none;
}

.contentview-link-item:hover {
  background-color: rgba(93, 5, 212, 0.4);
}

.contentview-link-name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-bottom: 2px solid rgba(93, 5, 212, 0.2);
  color: #73edd8;
}

.contentview-link-item-name {
  font-size: 18px;
  text-decoration: none;
}

.contentview-link-text {
  font-size: 12px;
  line-height: 16px;
}

.grid {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-areas: "Area";
  -ms-grid-columns: 1fr 10px 1fr 10px 1fr 10px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.contentview-article {
  border-top: 2px solid #5d05d4;
}

.heading {
  margin-top: 60px;
  color: #5d05d4;
}

.paragraph {
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 991px) {
  .contentview-filhos-fp {
    height: 300px;
    background-image: none;
  }
  .grid {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 767px) {
  p {
    font-size: 16px;
    line-height: 24px;
  }
  ul {
    padding-left: 30px;
  }
  .contentview-container.container-fp {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .contentview-filhos-fp {
    height: auto;
    background-image: none;
  }
  .contentview-fp-image {
    width: 100%;
    height: 170px;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .contentview-fp-phrase {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .contentview-fp-ttitle {
    font-size: 20px;
    line-height: 24px;
  }
  .contentview-youtube-tip-image {
    width: 250px;
    margin-left: 20px;
  }
  .contentview-slide-arrow {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
  .slide-nav {
    display: none;
  }
  .grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 479px) {
  p {
    font-size: 15px;
    line-height: 21px;
  }
  li {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 20px;
  }
  .contentview-container.container-fp {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .contentview-filhos-fp {
    background-image: none;
  }
  .contentview-fp-ttitle {
    font-size: 19px;
  }
  .contentview-youtube-tip-image {
    margin-bottom: 20px;
    margin-left: 0px;
    float: none;
  }
  .contentview-youtubetip {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .contentview-link-item {
    min-height: auto;
  }
  .grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@font-face {
  font-family: 'Product Sans Light Italic';
  src: url('../fonts/ProductSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Product Sans Black';
  src: url('../fonts/ProductSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans Black Italic';
  src: url('../fonts/ProductSans-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Product Sans Bold';
  src: url('../fonts/ProductSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans Bold Italic';
  src: url('../fonts/ProductSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Product Sans Italic';
  src: url('../fonts/ProductSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Product Sans Medium Italic';
  src: url('../fonts/ProductSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Product Sans Light';
  src: url('../fonts/ProductSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans Medium';
  src: url('../fonts/ProductSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans Thin';
  src: url('../fonts/ProductSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans Thin Italic';
  src: url('../fonts/ProductSans-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Product Sans Regular';
  src: url('../fonts/ProductSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}