select, input {   
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.input.select {
    background-position: right 10px center !important;
}

.purple {
    color: #5d05d4 !important
}