body {
  height: 100%;
  min-height: 100%;
  font-family: 'Product Sans', sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

h2 {
  margin-top: 50px;
  margin-bottom: 10px;
  color: #5d05d4;
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
}

p {
  margin-bottom: 10px;
  color: #696969;
  font-size: 15px;
  line-height: 22px;
}

.login {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.login-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.login-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 480px;
  height: 100%;
  min-width: 480px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #fff;
  background-image: linear-gradient(149deg, #73edd8, #94caf3);
}

.login-right-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: right;
}

.login-right-header.left {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.login-no-account-link {
  height: 23px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
}

.login-no-account-link:hover {
  border-bottom: 2px solid #fff;
}

.login-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 80px;
  padding-left: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.login-heading {
  margin-bottom: 30px;
  color: #fff;
  line-height: 38px;
}

.login-heading-light {
  font-weight: 100;
}

.login-input-text {
  width: 100%;
  min-width: 100%;
  padding: 24px;
  border: 1px none #000;
  border-radius: 4px;
  background-color: rgba(93, 5, 212, 0.1);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.03);
  color: #fff;
  font-size: 16px;
}

.login-input-text::-webkit-input-placeholder {
  color: #fff;
}

.login-input-text:-ms-input-placeholder {
  color: #fff;
}

.login-input-text::-ms-input-placeholder {
  color: #fff;
}

.login-input-text::placeholder {
  color: #fff;
}

.btn {
  padding: 18px 26px;
  border-radius: 4px;
  background-color: #5d05d4;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  font-size: 18px;
  text-align: center;
}

.btn:hover {
  background-color: #410197;
}

.btn.btn-login {
  width: 100%;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.btn.btn-login.btn-loading {
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.btn.btn-medium {
  margin-bottom: 8px;
}

.btn.btn-medium.btn-next-content {
  width: 200px;
  min-width: 200px;
}

.btn.btn-medium.btn-next-content.btn-responsive {
  display: none;
}

.btn.btn-medium.new-topic {
  margin-bottom: 0px;
  font-size: 16px;
}

.btn.btn-start-trail {
  width: 230px;
}

.btn.btn-signup {
  width: 100%;
}

.btn.btn-photo-edit {
  margin-bottom: 10px;
  padding: 1px 8px 0px;
  border-radius: 2px;
  font-size: 12px;
}

.btn.btn-small {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}

.btn.btn-small.outline {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(93, 5, 212, 0.5);
  background-color: #fff;
}

.btn.btn-small.outline.cancel {
  margin-right: 10px;
  color: rgba(93, 5, 212, 0.5);
}

.btn.btn-small.outline.cancel:hover {
  border-color: #5d05d4;
  color: #5d05d4;
}

.btn.renata {
  width: 230px;
}

.login-forgot {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-bottom: 1px solid transparent;
  color: #fff;
  line-height: 14px;
  text-decoration: none;
}

.login-forgot:hover {
  border-bottom-color: #fff;
}

.login-left-middle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.gray-16 {
  color: #9b9b9b;
  font-size: 16px;
}

.login-person {
  height: 393px;
  min-width: 325px;
  background-image: url('../images/menina-azul.png');
  background-position: 50% 50%;
  background-size: 325px 393px;
  background-repeat: no-repeat;
}

.login-content {
  max-width: 380px;
  margin-left: 40px;
}

.login-content-title {
  color: #5d05d4;
  font-size: 38px;
  line-height: 36px;
}

.login-content-text {
  color: #9b9b9b;
  font-size: 18px;
  font-weight: 300;
}

.login-right-logo {
  display: none;
}

.error-message {
  margin-bottom: 10px;
  padding-left: 4px;
  border-radius: 4px;
}

.error-message.bg-color {
  margin-bottom: 16px;
  padding: 14px 20px;
  background-color: hsla(0, 0%, 100%, 0.5);
}

.text-block {
  background-color: hsla(0, 0%, 100%, 0.2);
  color: #d64782;
  line-height: 14px;
  text-align: left;
}

.btn-loading {
  background-image: url('../images/loading.gif');
  background-position: 0px 0px;
  background-size: 125px;
  background-repeat: no-repeat;
}

.arrow-left {
  width: 24px;
  margin-right: 8px;
}

.login-right-logo-white {
  display: none;
}

.player {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 100%;
}

.player-menu {
  overflow: scroll;
  width: 350px;
  height: 100%;
  min-height: 100%;
  box-shadow: inset -20px 0 10px -10px rgba(0, 0, 0, 0.1);
}

.player-cover {
  height: 152px;
  background-image: url('../images/cover-forca-de-carater.png');
  background-position: 50% 50%;
}

.player-cover-shaddow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 14px 20px 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(2, 2, 2, 0.5)));
  background-image: linear-gradient(180deg, transparent, rgba(2, 2, 2, 0.5));
  box-shadow: inset -20px 0 10px -10px rgba(0, 0, 0, 0.3);
}

.player-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.player-name {
  margin-bottom: 8px;
  color: #fff;
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
}

.player-progress-bar {
  height: 3px;
  margin-bottom: 4px;
  border-radius: 4px;
  background-color: hsla(0, 0%, 100%, 0.3);
}

.plyaer-progress-bar-percent {
  width: 16%;
  height: 100%;
  border-radius: 4px;
  background-color: rgba(115, 237, 216, 0.5);
}

.player-percet-text {
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 11px;
  line-height: 14px;
  font-weight: 700;
}

.player-menu-list {
  padding-top: 24px;
  padding-bottom: 24px;
}

.player-lesson-name {
  width: 70%;
  min-width: 70%;
  margin-top: 20px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 24px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #73edd8;
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}

.player-menu-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 14px 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 84.7%, 0.5);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #9b9b9b;
  text-decoration: none;
}

.player-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.player-menu-item.selected {
  background-color: hsla(0, 0%, 84.7%, 0.4);
  color: #4a4a4a;
}

.player-menu-item-icon {
  width: 28px;
  height: 28px;
  margin-right: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 84.7%, 0.5);
  border-radius: 4px;
  background-image: url('../images/icon-play.svg');
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.player-menu-item-icon.selected {
  border-style: none;
  background-color: #73edd8;
  background-image: url('../images/icon-play-white.svg');
}

.player-menu-item-icon.viewed {
  border-style: none;
  background-color: rgba(74, 74, 74, 0.08);
}

.player-menu-item-title {
  margin-right: 10px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 14px;
}

.player-menu-item-time {
  color: hsla(0, 0%, 60.8%, 0.4);
  font-size: 12px;
}

.player-content {
  min-height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.player-content-head {
  position: fixed;
  z-index: 1001;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 96px;
  margin-bottom: 4px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 350px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #e0e0e0;
}

.player-content-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 940px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.player-content-head-icon {
  width: 33px;
  height: 33px;
  min-width: 33px;
  border-radius: 4px;
  background-color: rgba(74, 74, 74, 0.08);
  background-image: url('../images/icon-play.svg');
  background-position: 50% 50%;
  background-size: 15px 16px;
  background-repeat: no-repeat;
}

.player-content-h1 {
  margin: 0px 14px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #4a4a4a;
  font-size: 24px;
  line-height: 26px;
}

.btn-medium {
  padding-top: 14px;
  padding-bottom: 12px;
  font-size: 14px;
  line-height: 22px;
}

.player-content-video {
  overflow: scroll;
  height: 100%;
  padding-top: 96px;
  padding-left: 350px;
}

.player-menu-icon {
  display: none;
}

.player-menu-close {
  display: none;
  width: 14px;
  height: 14px;
  background-image: url('../images/icon-close-white.svg');
  background-position: 0px 0px;
  background-size: 14px;
}

.player-header {
  display: none;
}

.player-menu-container {
  width: 350px;
  height: 100%;
  background-color: #fafafa;
  box-shadow: inset -20px 0 10px -10px rgba(0, 0, 0, 0.1);
}

.div-block {
  position: fixed;
  z-index: 1002;
  background-color: #fafafa;
}

.container {
  width: 1120px;
  max-width: 100%;
}

.nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 14px 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image {
  width: 130px;
}

.breadcrumbs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #5d05d4;
  color: #fff;
}

.menu-icon {
  display: none;
  width: 32px;
  height: 32px;
  margin-right: 20px;
  background-image: url('../images/menu-purple.svg');
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.nav-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #4a4a4a;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}

.nav-link:hover {
  color: #5d05d4;
}

.nav-list {
  position: -webkit-sticky;
  position: sticky;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  margin-left: 25px;
  padding-left: 0px;
  list-style-type: none;
}

.subnav-list {
  position: absolute;
  display: none;
  margin-bottom: 0px;
  padding-left: 0px;
  border-radius: 0px;
  background-color: #fff;
  opacity: 0;
  list-style-type: none;
}

.nav-list-item {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.menu-icon-forum {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  background-image: url('../images/forum-icon.svg');
  background-position: 50% 50%;
  background-size: auto;
}

.subnav-liink {
  width: 100%;
  padding: 10px 20px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #5d05d4;
  text-decoration: none;
  white-space: nowrap;
}

.subnav-liink:hover {
  background-color: #5d05d4;
  color: #fff;
}

.header-user {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-user:hover {
  background-color: #f5f5f5;
}

.header-user-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.header-user-photo {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-image: url('../images/marloscarmo.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.header-user-name {
  color: #9b9b9b;
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
}

.header-user-firstname {
  color: #5d05d4;
  font-size: 15px;
}

.header-user-signout {
  color: #9b9b9b;
  font-size: 11px;
  line-height: 12px;
  text-decoration: none;
}

.header-user-signout:hover {
  text-decoration: underline;
}

.bread-home {
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
}

.bread-icon {
  width: 16px;
  height: 16px;
  margin-top: -2px;
  margin-right: 10px;
  margin-left: 10px;
  background-image: url('../images/icon-house.svg');
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.bread-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bread-separator {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: hsla(0, 0%, 100%, 0.5);
  color: hsla(0, 0%, 100%, 0.5);
}

.bread-link {
  margin-left: 8px;
  color: hsla(0, 0%, 100%, 0.5);
  font-weight: 400;
  text-decoration: none;
}

.dash-banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: linear-gradient(315deg, #7f3184, #d64782 54%);
}

.dash-banner-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 300px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.dash-banner-image {
  width: 50%;
  height: 100%;
  min-height: 100%;
  background-image: url('../images/mae-filha-pink.png');
  background-position: 50% 50%;
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.dash-banner-image.renata {
  width: 33.33%;
  background-image: url('../images/renata-livramento_1.png');
  background-size: auto 70%;
}

.dash-banner-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 66.66%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.dash-banner-content.renata {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dash-banner-title {
  width: 320px;
  margin-bottom: 10px;
  color: #670291;
  font-size: 40px;
  line-height: 36px;
  font-weight: 700;
}

.dash-banner-title.reanta {
  width: 100%;
}

.dash-banner-description {
  width: 320px;
  color: #fff;
  font-size: 18px;
}

.dash-main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f3f3f3;
}

.dash-title {
  margin-bottom: 20px;
  color: #9b9b9b;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
}

.dash-title.next-trail {
  margin-top: 60px;
}

.dash-trail-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 236px;
  margin-bottom: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 3px 7px 1px rgba(0, 0, 0, 0.14);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #5d05d4;
  text-decoration: none;
}

.dash-trail-box:hover {
  box-shadow: 0 3px 7px 1px rgba(93, 5, 212, 0.3);
}

.dash-trail-box.text-red:hover {
  box-shadow: 0 3px 7px 1px rgba(203, 66, 59, 0.14);
}

.dash-trail-box.text-green:hover {
  box-shadow: 0 3px 7px 1px rgba(36, 107, 149, 0.14);
}

.dash-trail-img {
  width: 330px;
  background-image: url('../images/trilha-filhos-2-1.png');
  background-position: 100% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.dash-trail-img.comunicacao-saudavel {
  background-image: url('../images/comunicacao-red.png');
  background-position: 100% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.dash-trail-img.produtividade {
  background-image: url('../images/produtividade.png');
}

.dash-trail-courses {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 390px;
  height: 100%;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.dash-trail-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.dash-trail-h2 {
  margin-top: 0px;
  font-size: 30px;
  line-height: 32px;
}

.dash-trail-h2.text-red {
  color: #cb423b;
}

.dash-trail-h2.text-green {
  color: #006b95;
}

.dash-trail-description {
  color: #7e7e7e;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
}

.dash-trail-courses-head {
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 700;
}

.dash-course-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-right: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.dash-course-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32px;
  height: 34px;
  min-width: 32px;
  margin-right: 10px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/icon-number-green.png');
  background-position: 0% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  color: #5d05d4;
  font-size: 16px;
  font-weight: 700;
}

.dash-course-icon.red {
  background-image: url('../images/icon-number-yellow.png');
  color: #cb423b;
}

.dash-course-icon.blue {
  background-image: url('../images/icon-number-blue.png');
  color: #006b95;
}

.dash-course-name {
  color: #7e7e7e;
  font-size: 18px;
  line-height: 18px;
}

.text-red {
  color: #cb423b;
}

.text-green {
  color: #006b95;
}

.app-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #d7d6dd;
  color: #696969;
}

.footer-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.footer-logo {
  width: 104px;
  height: 34px;
  margin-right: 40px;
  background-image: url('../images/itinere-gray.png');
  background-position: 0px 0px;
  background-size: contain;
  background-repeat: no-repeat;
}

.footer-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.menu-footer-link {
  padding: 1px 10px;
  color: #696969;
  text-decoration: none;
}

.menu-footer-link:hover {
  text-decoration: underline;
}

.footer-apps {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.footer-google-play {
  width: 105px;
  height: 32px;
  margin-right: 8px;
  background-image: url('../images/google-play.png');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.app-store {
  width: 98px;
  height: 32px;
  background-image: url('../images/app-store.png');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.header-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.app-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.course-detail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f3f3f3;
}

.courser-detail-banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: linear-gradient(315deg, #94caf3, #73edd8 54%);
}

.course-detail-banner-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 300px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.course-detail-banner-image {
  width: 50%;
  height: 100%;
  min-height: 100%;
  background-image: url('../images/course-img-filhos-2-1.png');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.course-detail-banner-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.course-detail-banner-title {
  width: 490px;
  margin-bottom: 20px;
  color: #5d05d4;
  font-size: 46px;
  line-height: 40px;
  font-weight: 700;
}

.course-detail-bg-left {
  height: 100%;
  min-height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.course-detail-bg-right {
  min-height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #e3e3e3;
}

.course-detail-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.course-detail-main {
  padding-top: 20px;
  padding-right: 40px;
  padding-bottom: 60px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.course-detail-aside {
  width: 360px;
  padding: 70px 30px;
  background-color: #e3e3e3;
  box-shadow: -3px 0 5px -3px rgba(0, 0, 0, 0.25);
}

.course-detail-list-learning {
  display: -ms-grid;
  display: grid;
  margin-top: 20px;
  padding-left: 0px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  list-style-type: none;
  -webkit-column-count: 2;
  column-count: 2;
}

.course-detail-list-learning-item {
  padding-left: 40px;
  background-image: url('../images/bullet-green.svg');
  background-position: 0% 0%;
  background-size: 26px 25px;
  background-repeat: no-repeat;
  color: #696969;
  font-size: 15px;
  line-height: 22px;
}

.portlet {
  padding: 30px 40px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 2px 2px 7px 1px rgba(0, 0, 0, 0.14);
}

.portlet.portlet-specialist {
  margin-bottom: 30px;
  padding: 30px;
}

.portlet.forum-post {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding: 20px;
}

.portlet.forum-post.fixed {
  border-style: solid;
  border-width: 2px;
  border-color: rgba(93, 5, 212, 0.3);
}

.course-detail-portlet-left {
  width: 50%;
}

.course-detail-portlet-right {
  width: 50%;
  margin-left: 20px;
}

.course-detail-portlet {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.course-detail-portlet.portlet {
  margin-bottom: 30px;
}

.course-detail-content-description {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.course-detail-content-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 66px;
  height: 70px;
  min-width: 66px;
  margin-right: 14px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/icon-number-green-big.png');
  background-position: 0% 50%;
  background-size: 50px;
  background-repeat: no-repeat;
  color: #5d05d4;
  font-size: 35px;
  font-weight: 700;
}

.course-detail-content-icon.start {
  background-image: url('../images/icon-raio.svg');
  background-size: 56px;
}

.course-detail-content-icon.library {
  background-image: url('../images/icon-library.svg');
}

.course-detail-content-title {
  color: #73edd8;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
}

.course-detail-content-mini-desc {
  margin-top: 6px;
  color: #7e7e7e;
  font-size: 13px;
  line-height: 15px;
}

.course-detail-content-list {
  margin-bottom: 0px;
  padding-left: 0px;
  list-style-type: none;
}

.course-detail-content-list-item {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
}

.course-detail-content-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #696969;
  font-size: 13px;
  text-decoration: none;
}

.course-detail-content-link:hover {
  color: #5d05d4;
}

.course-detail-content-time {
  color: hsla(0, 0%, 41.2%, 0.5);
  font-size: 11px;
}

.course-detail-content-item-icon {
  width: 25px;
  height: 25px;
  border-radius: 3px;
  background-color: rgba(93, 5, 212, 0.05);
  background-image: url('../images/icon-content-video.svg');
  background-position: 50% 50%;
  background-size: 10px 11px;
  background-repeat: no-repeat;
}

.course-detail-content-item-name {
  margin-right: 12px;
  margin-left: 6px;
  padding-top: 1px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 13px;
}

.course-detail-content-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 18px;
  padding-top: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.btn-content-menu {
  margin-bottom: 8px;
  padding-top: 6px;
  padding-bottom: 5px;
  padding-left: 34px;
  border-radius: 4px;
  background-color: #5d05d4;
  background-image: url('../images/icon-content-play.svg');
  background-position: 12px 50%;
  background-size: auto;
  background-repeat: no-repeat;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-size: 13px;
}

.btn-content-menu:hover {
  background-color: #410197;
}

.btn-content-menu.inverted {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #9b9b9b;
}

.btn-content-menu.inverted:hover {
  border-color: rgba(93, 5, 212, 0.5);
  background-color: rgba(0, 0, 0, 0.03);
}

.btn-content-menu.inverted.map {
  background-image: url('../images/icon-content-map.svg');
  background-position: 9px 50%;
}

.btn-content-menu.inverted.forum {
  display: none;
  background-image: url('../images/icon-content-forum.svg');
  background-position: 10px 50%;
}

.course-detail-testimonial {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
}

.course-detail-testimonial-left {
  width: 50%;
  height: 230px;
  background-image: url('../images/depoimento-pai.png');
  background-position: 50% 100%;
  background-size: 285px;
  background-repeat: no-repeat;
}

.course-detail-testimonial-right {
  width: 50%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  background-image: url('../images/aspas-depoimento.svg');
  background-position: 100% 100%;
  background-size: 66px;
  background-repeat: no-repeat;
}

.course-detail-testimonial-description {
  color: #9b9b9b;
  font-size: 15px;
  line-height: 20px;
}

.course-detail-testimonial-name {
  margin-top: 20px;
  color: #5d05d4;
  font-weight: 700;
}

.course-detail-testimonial-function {
  color: #4a4a4a;
  font-size: 11px;
  line-height: 12px;
}

.course-detail-aside-info {
  margin-bottom: 30px;
}

.course-detail-aside-info-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #696969;
  font-size: 16px;
}

.course-detail-star {
  width: 15px;
  height: 14px;
  margin-bottom: 2px;
  margin-left: 4px;
  background-image: url('../images/course-star.svg');
  background-position: 0px 0px;
  background-size: 15px 14px;
}

.course-detail-star.opaque {
  opacity: 0.5;
}

.course-detail-aside-info-item-note {
  margin-right: 10px;
}

.course-detail-aside-title {
  margin-bottom: 4px;
  color: #5d05d4;
  font-weight: 700;
}

.course-detail-aside-first-video {
  width: 100%;
  height: 180px;
  margin-bottom: 30px;
  border-radius: 6px;
  background-image: url('../images/first-video.png');
  background-position: 50% 50%;
  background-size: cover;
  text-decoration: none;
}

.course-detail-aside-first-video-bg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(93, 5, 212, 0.3)), to(rgba(93, 5, 212, 0.8)));
  background-image: linear-gradient(180deg, rgba(93, 5, 212, 0.3), rgba(93, 5, 212, 0.8));
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.course-detail-aside-first-video-bg:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(93, 5, 212, 0)), to(rgba(93, 5, 212, 0.8)));
  background-image: linear-gradient(180deg, rgba(93, 5, 212, 0), rgba(93, 5, 212, 0.8));
  opacity: 0;
}

.course-detail-aside-first-video-play {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding-left: 36px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/icon-content-play.svg');
  background-position: 0% 50%;
  background-size: 28px;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 18px;
}

.course-detail-aside-specialist-head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.specialist-photo {
  width: 83px;
  height: 83px;
  background-image: url('../images/renata-livramento.png');
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.specialist-info {
  margin-left: 16px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.specialist-first-name {
  color: #696969;
  font-size: 24px;
  line-height: 22px;
}

.specialist-last-name {
  margin-bottom: 6px;
  color: #696969;
  font-size: 24px;
  line-height: 22px;
}

.link-block {
  width: 61px;
  height: 18px;
  border-radius: 3px;
  background-color: rgba(32, 90, 135, 0.1);
  background-image: url('../images/icon-linkedin.png');
  background-position: 50% 50%;
  background-size: 55px 12px;
  background-repeat: no-repeat;
}

.specialist-description {
  margin-top: 20px;
  color: #696969;
  font-size: 13px;
  line-height: 18px;
}

.dash-trail-box-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.signup {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.signup-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.signup-middle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.signup-middle-left {
  width: 407px;
  height: 435px;
  margin-right: 30px;
  background-image: url('../images/pai-filho-purple.png');
  background-position: 50% 50%;
  background-size: 407px;
  background-repeat: no-repeat;
}

.signup-middle-right {
  width: 340px;
  margin-left: 30px;
}

.signup-title {
  margin-bottom: 20px;
  color: #5d05d4;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
}

.input {
  height: 50px;
  padding: 12px 20px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(93, 5, 212, 0.3);
  border-radius: 6px;
  background-color: transparent;
  color: #5d05d4;
  font-size: 16px;
}

.input:focus {
  border-color: #5d05d4;
}

.input::-webkit-input-placeholder {
  color: #5d05d4;
}

.input:-ms-input-placeholder {
  color: #5d05d4;
}

.input::-ms-input-placeholder {
  color: #5d05d4;
}

.input::placeholder {
  color: #5d05d4;
}

.input.select {
  background-color: transparent;
  background-image: url('../images/combo-arrow.svg');
  background-position: 90px 50%;
  background-size: 10px 6px;
  background-repeat: no-repeat;
}

.input.select.profile {
  width: 100%;
}

.input.select.user-plan-select {
  margin-top: 20px;
  margin-bottom: 4px;
}

.input.select.user-credit-card {
  margin-top: 20px;
  margin-bottom: 0px;
  padding-left: 50px;
  background-image: url('../images/icon-credit-card-purple.svg'), url('../images/combo-arrow.svg');
  background-position: 6% 50%, 96% 50%;
  background-size: 24px, 10px 6px;
  background-repeat: no-repeat, no-repeat;
}

.input.select.new-topic {
  width: 50%;
  margin-bottom: 0px;
}

.input.vencimento {
  margin-right: 5px;
}

.input.cvv {
  margin-left: 5px;
}

.input.plan {
  border-style: none;
  background-color: rgba(93, 5, 212, 0.3);
  color: #fff;
}

.input.plan.select.white {
  background-image: url('../images/combo-arrow-white.svg');
}

.input.left {
  margin-right: 5px;
}

.input.right {
  margin-left: 5px;
}

.input.forum-new-post-textarea {
  height: auto;
  min-height: 250px;
}

.signup-form {
  margin-bottom: 0px;
}

.signup-middle-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.signup-pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
}

.signup-pagination-item {
  width: 10px;
  height: 10px;
  margin-right: 3px;
  margin-left: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(93, 5, 212, 0.3);
  border-radius: 50%;
  background-color: #fff;
}

.signup-pagination-item.current {
  border-style: none;
  background-color: #5d05d4;
}

.signup-two-columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.signup-term {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  padding-left: 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/bullet-green.svg');
  background-position: 0% 50%;
  background-size: 23px;
  background-repeat: no-repeat;
  color: #5d05d4;
}

.form {
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.user-two-columns {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.user-form-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.user-form-container-left {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.user-form-container-right {
  width: 150px;
  height: 150px;
  margin-left: 40px;
}

.user-photo-border {
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 4px;
  border-color: #73edd8;
  border-radius: 50%;
}

.user-photo {
  width: 100%;
  height: 100%;
  border: 3px solid #fff;
  border-radius: 50%;
  background-image: url('../images/marloscarmo.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.user-photo-btn-hold {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  opacity: 0;
  cursor: pointer;
}

.user-photo-btn-hold:hover {
  opacity: 1;
}

.user {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #f3f3f3;
}

.user-bg-left {
  height: 100%;
  min-height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.user-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.user-main {
  padding-top: 20px;
  padding-right: 40px;
  padding-bottom: 60px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.user-aside {
  width: 360px;
  padding: 70px 30px;
  background-color: #e3e3e3;
  box-shadow: -3px 0 5px -3px rgba(0, 0, 0, 0.25);
}

.user-bg-right {
  min-height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #e3e3e3;
}

.user-aside-title {
  color: #5d05d4;
  font-size: 26px;
  line-height: 26px;
}

.text-block-2 {
  margin-top: 10px;
  color: #696969;
  font-size: 14px;
  line-height: 16px;
}

.user-plan-price {
  margin-top: 20px;
  color: #5d05d4;
  font-size: 170px;
  line-height: 120px;
  font-weight: 700;
  text-align: center;
  letter-spacing: -12px;
}

.text-block-3 {
  margin-top: 10px;
  color: #73edd8;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
}

.user-plan-form {
  margin-bottom: 0px;
}

.user-plan-cancel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  color: #9b9b9b;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
}

.user-plan-cancel:hover {
  text-decoration: underline;
}

.forum {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f3f3f3;
}

.forum-container {
  margin-bottom: 40px;
}

.forum-container.new-topic {
  margin-bottom: 80px;
}

.forum-head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.heading {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.forum-post-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.forum-post-replies {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.forum-post-author {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.forum-post-tag {
  padding-right: 6px;
  padding-left: 6px;
  border-radius: 3px;
  background-color: #5d05d4;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
}

.forum-post-tag.red {
  background-color: #cb423b;
}

.forum-post-tag.green {
  background-color: #006b95;
}

.forum-post-tag.blue {
  background-color: #73edd8;
}

.forum-post-title {
  margin-top: 4px;
  color: #696969;
  font-size: 20px;
  line-height: 28px;
}

.forum-post-description {
  margin-top: 6px;
  color: #9b9b9b;
  line-height: 16px;
}

.forum-post-replies-number {
  color: #696969;
  font-size: 32px;
  line-height: 25px;
  font-weight: 300;
  text-align: center;
}

.forum-post-replies-text {
  color: #696969;
  text-align: center;
}

.forum-post-photo {
  width: 52px;
  height: 52px;
  margin-right: 14px;
  border-radius: 50%;
  background-image: url('../images/marloscarmo.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.forum-post-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.forum-post-author-name {
  color: #5d05d4;
  font-size: 16px;
  font-weight: 700;
}

.forum-post-author-since {
  color: #696969;
  line-height: 16px;
}

.forum-post-fixed {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.forum-pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
  padding-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.forum-pagination-item {
  padding: 8px 10px 6px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #696969;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
}

.forum-pagination-item:hover {
  color: #000;
}

.forum-pagination-item.current {
  margin-right: 4px;
  padding-right: 12px;
  padding-left: 12px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
}

.pagination-arrow {
  width: 32px;
  height: 30px;
  margin-right: 6px;
  background-image: url('../images/arrow-left-gray.svg');
  background-position: 50% 50%;
  background-size: 18px;
  background-repeat: no-repeat;
  opacity: 0.5;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.pagination-arrow:hover {
  opacity: 1;
}

.pagination-arrow.right {
  background-image: url('../images/arrow-right-gray.svg');
}

.forum-post-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.forum-two-columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.forum-post-detail-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.forum-post-detail-title {
  margin-top: 8px;
  margin-right: 20px;
  margin-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #4a4a4a;
  font-size: 36px;
  line-height: 36px;
}

.forum-post-detail-two-columns {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.forum-post-detail-content {
  width: 100%;
  padding-top: 20px;
}

.forum-post-detail-replies {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #d7d6dd;
}

.forum-post-detail-no-replies {
  color: #4a4a4a;
  font-size: 24px;
  font-weight: 700;
}

.forum-post-detail-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.common {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.common-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 100px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.common-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.common-right {
  width: 50%;
  height: 200px;
  background-image: url('../images/no-access.svg');
  background-position: 50% 50%;
  background-size: 345px;
  background-repeat: no-repeat;
}

.common-right._404 {
  height: 280px;
  background-image: url('../images/404.svg');
}

.common-title {
  color: #5d05d4;
  font-size: 36px;
  line-height: 32px;
}

.common-description {
  margin-top: 14px;
  color: #696969;
  font-size: 16px;
}

.common-link {
  color: #5d05d4;
}

.modal-box {
  position: absolute;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.portlet-modal {
  min-width: 300px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 2px 2px 7px 1px rgba(0, 0, 0, 0.14);
}

.portlet-modal.portlet-specialist {
  margin-bottom: 30px;
  padding: 30px;
}

.portlet-modal.forum-post {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding: 20px;
}

.portlet-modal.forum-post.fixed {
  border-style: solid;
  border-width: 2px;
  border-color: rgba(93, 5, 212, 0.3);
}

.portlet-modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.portlet-modal-title {
  color: #696969;
  font-size: 18px;
}

.portlet-modal-close {
  width: 20px;
  height: 20px;
  background-image: url('../images/close-btn-gray.svg');
  background-position: 50% 50%;
  background-size: 14px;
  background-repeat: no-repeat;
}

.portlet-modal-main {
  padding: 20px;
  color: #696969;
  font-size: 14px;
}

.portlet-modal-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.player-nav-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  text-decoration: none;
}

.player-nav-link:hover {
  color: #001a24;
}

.player-nav-text {
  margin-top: 2px;
  margin-left: 6px;
  font-weight: 700;
  text-decoration: none;
}

.player-nav-text.close-menu {
  display: none;
}

.menu-item-small {
  font-size: 12px;
  font-weight: 700;
}

.player-menu-form {
  padding-top: 24px;
  padding-right: 24px;
  padding-left: 24px;
}

.dash-banner-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 33.33%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

@media (max-width: 991px) {
  .login {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .login-left {
    width: auto;
    height: auto;
    padding: 20px;
  }
  .login-right {
    width: 100%;
    height: 100%;
    min-width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .login-right-header {
    text-align: center;
  }
  .login-form {
    padding-right: 100px;
    padding-bottom: 40px;
    padding-left: 100px;
  }
  .btn.btn-medium.btn-next-content {
    display: none;
  }
  .btn.btn-medium.btn-next-content.btn-responsive {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .btn.btn-start-trail {
    width: 170px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 16px;
    line-height: 18px;
  }
  .btn.renata {
    width: 270px;
    margin-right: 20px;
    font-size: 14px;
    line-height: 16px;
  }
  .login-left-header {
    text-align: center;
  }
  .login-left-middle {
    height: auto;
    padding-top: 40px;
    padding-bottom: 60px;
  }
  .gray-16 {
    display: none;
  }
  .login-person {
    width: 240px;
    height: 296px;
  }
  .login-content {
    width: 290px;
  }
  .login-content-title {
    line-height: 34px;
  }
  .player {
    display: block;
    height: auto;
    min-height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .player-menu {
    position: absolute;
    z-index: 1;
    display: block;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .player-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .player-menu-list {
    height: 100%;
  }
  .player-content {
    min-height: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .player-content-head {
    position: relative;
    z-index: auto;
    padding-left: 0px;
  }
  .player-content-h1 {
    font-size: 20px;
    line-height: 22px;
  }
  .player-content-video {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .player-menu-icon {
    display: block;
    width: 33px;
    height: 33px;
    margin-right: 14px;
    background-image: url('../images/icon-menu-white.svg');
    background-position: 50% 50%;
    background-size: 24px;
    background-repeat: no-repeat;
  }
  .player-menu-close {
    display: block;
  }
  .player-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 14px 20px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #73edd8;
  }
  .player-header-logo {
    height: 40px;
    min-height: 40px;
  }
  .player-menu-container {
    height: auto;
  }
  .div-block {
    position: fixed;
    display: block;
  }
  .container {
    width: 100%;
  }
  .nav {
    padding-left: 20px;
  }
  .image {
    width: 110px;
  }
  .breadcrumbs {
    padding: 10px 20px;
    font-size: 14px;
  }
  .header-user {
    padding-right: 20px;
    padding-left: 20px;
  }
  .dash-banner-container {
    height: 200px;
  }
  .dash-banner-title {
    width: 260px;
    font-size: 32px;
    line-height: 32px;
  }
  .dash-banner-description {
    width: 260px;
    font-size: 15px;
    line-height: 17px;
  }
  .dash-main {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .dash-main-container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .dash-title {
    margin-bottom: 16px;
    font-size: 26px;
    line-height: 28px;
  }
  .dash-title.next-trail {
    margin-top: 40px;
  }
  .dash-trail-box {
    height: 160px;
    margin-bottom: 30px;
  }
  .dash-trail-img {
    width: 210px;
  }
  .dash-trail-courses {
    width: 280px;
  }
  .dash-trail-content {
    padding-right: 10px;
    padding-left: 30px;
  }
  .dash-trail-h2 {
    font-size: 18px;
    line-height: 18px;
  }
  .dash-trail-description {
    font-size: 12px;
    line-height: 13px;
  }
  .dash-trail-courses-head {
    font-size: 14px;
    line-height: 14px;
  }
  .dash-course-icon {
    width: 26px;
    height: 25px;
    min-width: 26px;
    margin-right: 8px;
    padding-top: 1px;
    font-size: 12px;
  }
  .dash-course-name {
    font-size: 13px;
    line-height: 13px;
  }
  .app-footer {
    padding: 20px;
    font-size: 12px;
    line-height: 18px;
  }
  .menu-footer-link {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .header-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .header-nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .course-detail-banner-container {
    height: 200px;
  }
  .course-detail-banner-title {
    width: 280px;
    font-size: 26px;
    line-height: 26px;
  }
  .course-detail-container {
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .course-detail-main {
    padding-right: 0px;
  }
  .course-detail-aside {
    width: 100%;
    margin-bottom: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 6px;
    box-shadow: 0 3px 5px -3px rgba(0, 0, 0, 0.25);
  }
  .portlet.portlet-specialist.portlet-payment {
    margin-bottom: 0px;
  }
  .course-detail-aside-panels {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .course-detail-aside-panels-left {
    width: 50%;
    margin-right: 20px;
  }
  .courser-detail-aside-panels-right {
    width: 50%;
    margin-left: 20px;
  }
  .signup {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .signup-container {
    width: auto;
    height: auto;
    padding: 20px;
  }
  .signup-header {
    text-align: center;
  }
  .signup-middle {
    height: auto;
    padding-top: 40px;
    padding-bottom: 60px;
  }
  .signup-middle-left {
    width: 340px;
    height: 390px;
    background-size: contain;
  }
  .signup-middle-right {
    width: 290px;
  }
  .user-container {
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .user-main {
    padding-right: 0px;
  }
  .user-aside {
    width: 100%;
    margin-bottom: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 6px;
    box-shadow: 0 3px 5px -3px rgba(0, 0, 0, 0.25);
  }
  .user-aside-panels {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .user-aside-panels-right {
    width: 50%;
    margin-left: 20px;
  }
  .forum {
    padding-right: 20px;
    padding-left: 20px;
  }
  .forum-container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .forum-post-title {
    margin-bottom: 4px;
    line-height: 22px;
  }
  .common-container {
    padding-right: 60px;
    padding-left: 60px;
  }
  .common-right {
    background-size: 260px;
  }
  .common-title {
    font-size: 32px;
    line-height: 30px;
  }
  .portlet-modal.portlet-specialist.portlet-payment {
    margin-bottom: 0px;
  }
  .player-nav-text.close-menu {
    display: block;
  }
}

@media (max-width: 767px) {
  .login-left {
    display: none;
  }
  .login-right-header {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .login-form {
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .login-heading {
    display: none;
  }
  .btn.btn-start-trail {
    width: 150px;
    padding: 10px 20px;
    font-size: 15px;
  }
  .btn.renata {
    margin-right: 0px;
  }
  .login-right-logo {
    display: block;
    width: 200px;
    margin-bottom: 40px;
  }
  .login-form-block {
    width: 100%;
  }
  .login-right-logo-white {
    width: 200px;
  }
  .player-menu-close {
    display: block;
  }
  .breadcrumbs {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .menu-icon {
    display: none;
  }
  .nav-list {
    display: none;
  }
  .bread-home {
    font-size: 13px;
    line-height: 16px;
  }
  .bread-link {
    font-size: 12px;
    line-height: 16px;
  }
  .dash-banner-content {
    padding-right: 40px;
    padding-left: 20px;
  }
  .dash-banner-title {
    width: auto;
    font-size: 28px;
    line-height: 26px;
  }
  .dash-banner-description {
    width: auto;
    font-size: 13px;
    line-height: 15px;
  }
  .dash-trail-box {
    height: auto;
  }
  .dash-trail-img {
    width: 40%;
    background-size: cover;
  }
  .dash-trail-img.comunicacao-saudavel {
    background-size: cover;
  }
  .dash-trail-courses {
    width: 100%;
    padding-bottom: 20px;
  }
  .dash-trail-content {
    padding: 20px 0px 20px 20px;
  }
  .dash-trail-h2 {
    font-size: 22px;
    line-height: 23px;
  }
  .dash-trail-description {
    font-size: 11px;
  }
  .dash-course-item {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .dash-course-icon {
    width: 22px;
    height: 22px;
    min-width: 22px;
  }
  .dash-course-name {
    font-size: 12px;
  }
  .footer-left {
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .footer-right {
    display: none;
  }
  .footer-logo {
    margin-right: 0px;
  }
  .footer-menu {
    display: none;
  }
  .course-detail-banner-content {
    padding-right: 40px;
    padding-left: 20px;
  }
  .course-detail-banner-title {
    width: auto;
    font-size: 21px;
    line-height: 22px;
  }
  .course-detail-list-learning-item {
    font-size: 14px;
    line-height: 18px;
  }
  .portlet.forum-post {
    margin-top: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .course-detail-portlet-left {
    width: 100%;
  }
  .course-detail-portlet-right {
    width: 100%;
    margin-top: 16px;
    margin-left: 0px;
  }
  .course-detail-portlet.portlet {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .course-detail-content-menu {
    display: -ms-grid;
    display: grid;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .course-detail-testimonial {
    margin-top: 0px;
  }
  .course-detail-testimonial-left {
    width: 30%;
    height: 123px;
    margin-right: 20px;
    background-size: contain;
  }
  .course-detail-testimonial-right {
    width: 60%;
    background-size: 40px;
  }
  .course-detail-testimonial-description {
    font-size: 13px;
    line-height: 14px;
  }
  .course-detail-aside-panels {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .course-detail-aside-panels-left {
    width: 100%;
    margin-right: 0px;
  }
  .courser-detail-aside-panels-right {
    width: 100%;
    margin-left: 0px;
  }
  .course-detail-h2 {
    font-size: 32px;
  }
  .dash-trail-box-right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .signup-middle-left {
    display: none;
  }
  .input.select.new-topic {
    width: auto;
    margin-right: 10px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .user-form-container-right {
    width: 100px;
    height: 100px;
    margin-left: 30px;
  }
  .user-aside-panels {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .user-aside-panels-right {
    width: 100%;
    margin-left: 0px;
  }
  .user-h2 {
    font-size: 32px;
  }
  .forum-post-author {
    margin-top: 20px;
  }
  .forum-post-details {
    margin-top: 14px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .forum-post-detail-title {
    margin-right: 0px;
    font-size: 30px;
    line-height: 28px;
  }
  .forum-post-detail-two-columns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .forum-post-detail-replies {
    padding-right: 20px;
    padding-left: 20px;
  }
  .common-container {
    padding: 60px 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .common-left {
    width: 100%;
  }
  .common-right {
    width: 100%;
  }
  .common-title {
    text-align: center;
  }
  .portlet-modal.forum-post {
    margin-top: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 479px) {
  .login-form {
    padding-right: 20px;
    padding-left: 20px;
  }
  .btn {
    width: 100%;
  }
  .btn.btn-medium.new-topic {
    padding-bottom: 14px;
  }
  .btn.btn-photo-edit {
    width: auto;
  }
  .btn.renata {
    width: 80%;
    margin-top: 20px;
  }
  .login-right-logo {
    display: none;
  }
  .login-right-logo-white {
    display: block;
    margin-bottom: 40px;
  }
  .div-block {
    z-index: 100000;
    display: none;
  }
  .nav {
    padding-left: 10px;
  }
  .image {
    width: 80px;
  }
  .menu-icon {
    margin-right: 10px;
  }
  .header-user {
    padding-right: 10px;
    padding-left: 10px;
  }
  .header-user-photo {
    width: 28px;
    height: 28px;
  }
  .header-user-name {
    font-size: 12px;
  }
  .header-user-firstname {
    font-size: 14px;
  }
  .dash-banner {
    background-image: none;
  }
  .dash-banner-container {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dash-banner-image {
    width: 100%;
    height: 220px;
    min-height: auto;
    background-color: #d64782;
  }
  .dash-banner-image.renata {
    width: 100%;
    height: 200px;
    background-size: auto 80%;
  }
  .dash-banner-content {
    width: 100%;
    padding-top: 30px;
    padding-right: 20px;
    padding-bottom: 30px;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#7f3184), to(#d64782));
    background-image: linear-gradient(0deg, #7f3184, #d64782);
  }
  .dash-banner-content.renata {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .dash-banner-title {
    color: #fff;
    font-size: 36px;
    line-height: 30px;
    text-align: center;
  }
  .dash-banner-title.reanta {
    font-size: 31px;
  }
  .dash-banner-description {
    margin-top: 10px;
    font-size: 14px;
  }
  .dash-trail-box {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .dash-trail-img {
    width: 100%;
    height: 200px;
    background-position: 50% 0%;
  }
  .dash-trail-courses {
    width: 100%;
    padding: 20px 30px 30px;
  }
  .dash-trail-content {
    padding: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .dash-trail-h2 {
    width: 100%;
    margin-bottom: 14px;
    font-size: 28px;
    line-height: 26px;
    text-align: center;
  }
  .dash-trail-description {
    font-size: 14px;
    line-height: 15px;
  }
  .dash-trail-courses-head {
    width: 100%;
    font-size: 20px;
    line-height: 22px;
  }
  .dash-course-name {
    padding-top: 3px;
    font-size: 16px;
    line-height: 16px;
  }
  .courser-detail-banner {
    background-image: none;
  }
  .course-detail-banner-container {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#73edd8), to(#94caf3));
    background-image: linear-gradient(180deg, #73edd8, #94caf3);
  }
  .course-detail-banner-image {
    width: 100%;
    height: 220px;
    min-height: auto;
    background-image: url('../images/menina-azul.png');
    background-position: 50% -18%;
    background-size: auto 250px;
  }
  .course-detail-banner-content {
    width: 100%;
    padding-top: 30px;
    padding-right: 20px;
    padding-bottom: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .course-detail-banner-title {
    margin-bottom: 10px;
    font-size: 26px;
    line-height: 28px;
    text-align: center;
  }
  .course-detail-container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .course-detail-main {
    padding-top: 0px;
    padding-bottom: 40px;
  }
  .course-detail-aside {
    padding: 10px 20px 0px;
  }
  .course-detail-list-learning {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .course-detail-list-learning-item {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 16px;
  }
  .portlet.portlet-specialist {
    padding: 20px;
  }
  .course-detail-portlet.portlet {
    padding: 30px 20px;
  }
  .course-detail-content-description {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .course-detail-content-icon {
    height: 70px;
    margin-bottom: 10px;
    background-position: 50% 50%;
    background-size: contain;
    font-size: 25px;
  }
  .course-detail-content-icon.start {
    height: 70px;
    margin-bottom: 10px;
    background-position: 50% 50%;
    background-size: contain;
  }
  .course-detail-content-title {
    text-align: center;
  }
  .course-detail-content-mini-desc {
    text-align: center;
  }
  .course-detail-content-list-item {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .course-detail-content-time {
    display: none;
  }
  .course-detail-content-item-name {
    font-size: 12px;
    line-height: 12px;
  }
  .course-detail-content-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    border-top-width: 2px;
    border-top-color: rgba(0, 0, 0, 0.1);
  }
  .btn-content-menu {
    width: 100%;
  }
  .course-detail-testimonial-left {
    display: none;
  }
  .course-detail-testimonial-right {
    width: 100%;
  }
  .course-detail-aside-info-item {
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 15px;
  }
  .course-detail-aside-title {
    text-align: center;
  }
  .course-detail-aside-first-video {
    height: 140px;
  }
  .course-detail-aside-specialist-head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .specialist-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 10px;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .specialist-description {
    font-size: 12px;
    line-height: 16px;
  }
  .course-detail-h2 {
    font-size: 26px;
    line-height: 25px;
    text-align: center;
  }
  .course-detail-h2.content-detail {
    margin-bottom: 20px;
  }
  .course-detail-p {
    font-size: 14px;
    line-height: 17px;
  }
  .signup-container {
    padding-right: 0px;
    padding-left: 0px;
  }
  .signup-middle-right {
    margin-left: 0px;
  }
  .input {
    width: 100%;
  }
  .input.select.new-topic {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
    padding-top: 12px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .input.right {
    margin-left: 0px;
  }
  .input.forum-new-post-textarea {
    line-height: 18px;
  }
  .form {
    padding-bottom: 50px;
  }
  .user-two-columns {
    grid-auto-flow: row;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
  .user-form-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .user-form-container-left {
    width: 100%;
  }
  .user-form-container-right {
    margin-bottom: 20px;
    margin-left: 0px;
  }
  .user-container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .user-main {
    padding-top: 0px;
    padding-bottom: 30px;
  }
  .user-aside {
    padding-top: 30px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .user-aside-title {
    line-height: 24px;
    text-align: center;
  }
  .user-h2 {
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
  }
  .forum-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .forum-head {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .heading {
    font-size: 27px;
    text-align: center;
  }
  .forum-post-replies {
    width: 58px;
    margin-right: 14px;
  }
  .forum-post-author {
    width: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .forum-post-tag {
    padding-top: 1px;
    font-size: 10px;
    line-height: 18px;
  }
  .forum-post-title {
    font-size: 18px;
    line-height: 19px;
  }
  .forum-post-replies-number {
    font-size: 24px;
    line-height: 20px;
  }
  .forum-post-replies-text {
    font-size: 12px;
    line-height: 16px;
  }
  .forum-post-photo {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
  .forum-post-author-name {
    font-size: 14px;
    line-height: 14px;
  }
  .forum-post-author-since {
    font-size: 12px;
    line-height: 14px;
  }
  .forum-two-columns {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .forum-new-topic-form {
    width: 100%;
  }
  .forum-post-detail-container {
    padding-bottom: 40px;
  }
  .forum-post-detail-title {
    font-size: 22px;
    line-height: 22px;
  }
  .forum-post-detail-no-replies {
    font-size: 18px;
    line-height: 16px;
  }
  .common-right {
    height: 150px;
    margin-top: 20px;
    background-size: 220px;
  }
  .common-right._404 {
    height: 220px;
  }
  .portlet-modal.portlet-specialist {
    padding: 20px;
  }
  .dash-banner-right {
    width: 100%;
  }
}

@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/ProductSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/ProductSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/ProductSans-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/ProductSans-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/ProductSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/ProductSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/ProductSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/ProductSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/ProductSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/ProductSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/ProductSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/ProductSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}